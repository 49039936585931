/* Common imports */
import React from "react";
/* mui imports */

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, useMediaQuery, useTheme } from "@mui/material";
import StateInterface from "../../../../redux-magic/state-interface";
import { SearchFilterId, SearchFilterOption } from "../../../../redux-magic/sub-interfaces/search-types";
import {
	setSearchAnimationDetailsThunk,
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterSelectedOptionsThunk,
	updateSearchFilterVisibilityThunk,
} from "../../../../redux-magic/thunks";

/* stylings */

const PropertyType = ({
	search_id,
	property_category_value,
	search_filter_state,
	dispatch,
	searchanimationsettings,
}: {
	search_id: SearchFilterId;
	property_category_value: string;
	search_filter_state: StateInterface["search_filters_state"]["property_type"];
	dispatch: Function;
	searchanimationsettings: StateInterface["search_animation_settings"];
}) => {
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
	const handleClickRadioPropertyType = (value: Array<SearchFilterOption>) => {
		if (
			value[0].id === "plot" ||
			value[0].id === "sez" ||
			value[0].id === "building" ||
			value[0].id === "shop" ||
			value[0].id === "office_space" ||
			value[0].id === "retail" ||
			value[0].id === "hospitality" ||
			value[0].id === "healthcare" ||
			value[0].id === "educational" ||
			value[0].id === "farm_plot" ||
			value[0].id === "co_working" ||
			value[0].id === "factory" ||
			value[0].id === "plot_commercial"
		) {
			dispatch(
				setSearchAnimationDetailsThunk({
					StartAnimate: false,
					TriggerSearch: false,
					PropertySubCategory: ["Budget", "Status", "Listed by", "Facing"],
					ResetSearch: searchanimationsettings.ResetSearch,
					HomeAccordion: searchanimationsettings.HomeAccordion,
					OpenLocationDrawer: searchanimationsettings.OpenLocationDrawer,
				}),
			);
		} else if (
			value[0].id === "warehouse_industrial" ||
			value[0].id === "warehouse_cold_storage" ||
			value[0].id === "warehouse_commercial" ||
			value[0].id === "warehouse"
		) {
			dispatch(
				setSearchAnimationDetailsThunk({
					StartAnimate: false,
					TriggerSearch: false,
					PropertySubCategory: ["Budget", "Status", "Listed by"],
					ResetSearch: false,
					HomeAccordion: searchanimationsettings.HomeAccordion,
					OpenLocationDrawer: false,
				}),
			);
		} else if (
			value[0].id === "land_commercial" ||
			value[0].id === "land_residential" ||
			value[0].id === "land_agricultural" ||
			value[0].id === "land_industrial"
		) {
			dispatch(
				setSearchAnimationDetailsThunk({
					StartAnimate: false,
					TriggerSearch: false,
					PropertySubCategory: ["Budget", "Listed by"],
					ResetSearch: false,
					HomeAccordion: searchanimationsettings.HomeAccordion,
					OpenLocationDrawer: false,
				}),
			);
		} else if (value[0].id === "mining") {
			dispatch(
				setSearchAnimationDetailsThunk({
					StartAnimate: false,
					TriggerSearch: false,
					PropertySubCategory: ["Budget", "Listed by", "Facing"],
					ResetSearch: false,
					HomeAccordion: searchanimationsettings.HomeAccordion,
					OpenLocationDrawer: false,
				}),
			);
		} else {
			dispatch(
				setSearchAnimationDetailsThunk({
					StartAnimate: false,
					TriggerSearch: false,
					PropertySubCategory: ["Budget", "Bedroom", "Status", "Listed by", "Facing"],
					ResetSearch: false,
					HomeAccordion: searchanimationsettings.HomeAccordion,
					OpenLocationDrawer: false,
				}),
			);
		}

		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: value,
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: search_filter_state.active_options.map((item) => ({
					...item,
					is_selected: item.id === value[0]?.id ? true : false,
				})),
			}),
		);
	};

	React.useEffect(() => {
		if (isDesktop) {
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: search_id,
					isApplied: search_filter_state.selected_options.length != 0 ? true : false,
				}),
			);
			search_filter_state.affects.map((item: SearchFilterId) => {
				dispatch(
					updateSearchFilterVisibilityThunk({
						searchFilterId: item,
						isVisible: search_filter_state.selected_options.length != 0 ? true : false,
					}),
				);

				if (search_filter_state.selected_options.length === 0) {
					dispatch(
						updateSearchFilterSelectedOptionsThunk({
							searchFilterId: item,
							newOptions: [],
						}),
					);
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search_filter_state, search_id, dispatch]);

	return (
		<FormControl
			sx={{
				marginBottom: "1rem",
				overflow: "hidden",
				marginTop: "1rem",
			}}
		>
			<FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
			<RadioGroup
				row
				aria-labelledby="demo-row-radio-buttons-group-label"
				name="row-radio-buttons-group"
				sx={{ fontSize: "1rem", fontWeight: 400, paddingLeft: "0.75rem" }}
			>
				{search_filter_state.active_options.map((data) => (
					<FormControlLabel
						id={"home_desktop_filters_property_type_"}
						key={data.id}
						value={data.value}
						control={
							<Radio
								size="small"
								checked={search_filter_state?.selected_options[0]?.id === data.id}
							/>
						}
						label={data.title === "Co-living" ? "Co-living/PG" : data.title}
						onChange={() => {
							search_filter_state?.selected_options[0]?.id === data.id
								? handleClickRadioPropertyType([] as Array<SearchFilterOption>)
								: handleClickRadioPropertyType([data] as Array<SearchFilterOption>);
						}}
					/>
				))}
			</RadioGroup>
		</FormControl>
	);
};
export default PropertyType;
